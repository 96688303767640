.mapBox {
    background: "red";
    border: "1px solid";
    max-width: "250px";
    min-height: "100px";
}

.jvectormap-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    touch-action: none;
    background-color: #FF6700;
}

.jvectormap-zoomin {
    top: 20px;
    left: 20px;
    background: white;
    color: #2262c6;
}

.jvectormap-zoomin {
    position: absolute;
    left: 10px;
    border-radius: 3px;
    background: #292929;
    padding: 3px;
    color: white;
    cursor: pointer;
    line-height: 10px;
    text-align: center;
    box-sizing: content-box;
}

.jvectormap-zoomout {
    top: 40px;
    left: 20px;
    background: white;
    color: #2262c6;
}

.jvectormap-zoomin,
.jvectormap-zoomout {
    width: 10px;
    height: 10px;
}

.jvectormap-zoomout {
    position: absolute;
    left: 10px;
    border-radius: 3px;
    background: #292929;
    padding: 3px;
    color: white;
    cursor: pointer;
    line-height: 10px;
    text-align: center;
    box-sizing: content-box;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}
.jvectormap-tip:last-child {
    position: absolute;
    display: none;
    border: solid 1px #CDCDCD;
    border-radius: 3px;
    background: #292929;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: smaller;
    padding: 3px;
}

#world-map .gmnoprint {
    display: none;
}

.talaMap{
    height: 480px;
    border: 0.7px solid #094d7286;
}
@media (min-width: 1680px) and (max-width: 2560px)  {
    .talaMap{
        height: 100%;
    }
}
