.searchable-items.grid .items {
    margin-bottom: 30px;
    border-radius: 6px;
    width: 100%;
    color: #0e1726;
    transition: all 0.35s ease;
    width: 50%;
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.searchable-items.grid {
    padding: 0px;
    background: transparent;
    box-shadow: none;
}

.searchable-items.grid .items .item-content {
    background-color: #fff;
    padding: 13px 18px;
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.09019607843137255), 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    cursor: pointer;
}

.badge-info {
    color: #fff;
    background-color: #2196f3;
}

.badge {
    font-weight: 500 !important;
    line-height: 1.4 !important;
    padding: 7px 7px !important;
    font-size: 12px !important;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    vertical-align: baseline;
    border-radius: 0.25rem !important;
}

.searchable-items.grid .items .user-meta-info {
    margin-top: 10px;
}

a.btn.btn-sm.btn-dark.mybtn {
    padding: 0.4375rem 1.25rem;
}

.btn-dark {
    color: #fff !important;
    background-color: #3b3f5c !important;
    border-color: #3b3f5c !important;
}

.card-box {
    background-color: #fff;
    padding: 1.4rem 1.2rem;
    /* box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.15); */
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.09019607843137255), 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06);
    /* margin-bottom: 25px; */
    border-radius: 0.25rem;
}

.btn-dark:hover {
    color: #fff !important;
    background-color: #3b3f5c;
    box-shadow: none;
    border-color: #3b3f5c;
}

.annualTitles {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0px;
    margin-bottom: 0;
    color: black;
}

:where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper .ant-table-tbody>tr>td {
    position: relative;
    /* padding: 12px 8px !important; */
    overflow-wrap: break-word;
    font-size: 13px !important;
}

:where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper .ant-table-column-title {
    position: relative;
    z-index: 1;
    flex: 1;
    font-size: 12px !important;
    color: #FF6700 !important;
    font-weight: 700 !important;
}

th.ant-table-cell {
    font-size: 13px !important;
    color: #FF6700 !important;
    font-weight: 700 !important;
    font-family: 'Poppins', sans-serif !important;
}

:where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper .ant-table-tbody>tr>th,
:where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper .ant-table-tbody>tr>td,
:where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper tfoot>tr>th,
:where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper tfoot>tr>td {
    position: relative;
    padding: 16px 10px !important;
    overflow-wrap: break-word;
    font-size: 13px !important;
    /* text-align: center !important; */
}


.btn-outline-success {
    --bs-btn-hover-bg: #FF6700 !important;
    --bs-btn-color: #FF6700 !important;
    --bs-btn-border-color: #FF6700 !important;
}


:where(.css-dev-only-do-not-override-htwhyh).ant-carousel .slick-dots li.slick-active button {
    background: #FF6700 !important;
    opacity: 1;
}

.dataNotfound {
    margin-top: 40px;
    margin-bottom: 40px;
}

.headingEvent {
    margin: 0;
    color: #fff;
    text-align: center;
    background: rgb(0 0 0 / 70%);
    position: absolute;
    bottom: 80px;
    margin-left: 50px;
    z-index: 10;
    padding: 6px 30px 10px 33px;
    font-size: 20px;
}

.headingBtnEvent {
    margin: 0;
    color: black;
    text-align: center;
    position: absolute;
    bottom: 30px;
    margin-left: 20px;
    z-index: 10;
    padding: 6px 12px 10px 12px;
    font-size: 15px;
    font-weight: 600;
    background: #FF6700;
    cursor: pointer
}

.exhi_btn
{
    border: 1px solid white !important;
}

.exhibitionBannerCard {
    position: relative;
    padding: 10px 20px 10px 20px;
    border-radius: 8px;
    border: none;
    background: #fff;
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
}

.btnactive {
    /* border-color: #6a8da5 !important; */
    background-color: #FF6700;
    color: white;
    border: 1px solid #FF6700 !important;
}

.btn-outline-dark {
    border: 1px solid #FF6700 !important;
    color: #FF6700 !important;
}

.btn-outline-dark:hover {
    color: #ffffff !important;
    background-color: #FF6700 !important;
    border: 1px solid #FF6700 !important
}

/* .btn:hover {
    color: #FF6700;
    background-color: white;
} */

.btn-primary:not([disabled]):not(.disabled).hover,
.btn-primary:not([disabled]):not(.disabled):hover {
    color: #fff !important;
    background-color: #FF6700 !important;
    border-color: #FF6700 !important;
}

.btn-outline-success {
    color: #FF6700 !important;
    border-color: #FF6700 !important;
}

.contactUs {
    position: relative;
    padding: 0px 20px;
    color: #FF6700;
    overflow: hidden;
    height: 37px;
}

.contactUs span:nth-child(1) {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to right, #171618, #0066b0);
    animation: animate1 2s linear infinite;
}

.contactUs:hover span:nth-child(1) {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to right, #171618, #899800);
    animation: animate1 2s linear infinite;
}

@keyframes animate1 {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

.contactUs span:nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 3px;
    background: linear-gradient(to bottom, #171618, #0066b0);
    animation: animate2 2s linear infinite;
    animation-delay: 1s;
}

.contactUs:hover span:nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 3px;
    background: linear-gradient(to bottom, #171618, #899800);
    animation: animate2 2s linear infinite;
    animation-delay: 1s;
}

@keyframes animate2 {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(100%);
    }
}

.contactUs span:nth-child(3) {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to left, #171618, #0066b0);
    animation: animate3 2s linear infinite;
}

.contactUs:hover span:nth-child(3) {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to left, #171618, #899800);
    animation: animate3 2s linear infinite;
}

@keyframes animate3 {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.contactUs span:nth-child(4) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 3px;
    background: linear-gradient(to top, #171618, #0066b0);
    animation: animate4 2s linear infinite;
    animation-delay: 1s;
}

.contactUs:hover span:nth-child(4) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 3px;
    background: linear-gradient(to top, #171618, #899800);
    animation: animate4 2s linear infinite;
    animation-delay: 1s;
}

@keyframes animate4 {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(-100%);
    }
}

.unread-messages {
    background-color: #e74c3c;
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 5px;
    font-size: 10px;
    font-weight: 500;
    /* margin-bottom: -8px;
    z-index: 2222; */
    justify-content: center;
    align-items: center;
    display: flex;
}

.unread-messages-dropdown {
    justify-content: center;
    align-items: center;
    color: #fff;
    /* padding: 3px 5px; */
    border-radius: 50%;
    font-size: 9px;
    font-weight: 500;
    width: 15px;
    height: 15px;
    background-color: #e74c3c;
    display: flex;
}

.btn-outline-success {
    color: #FF6700 !important;
    border-color: #FF6700 !important;
    border-width: 1.5px !important;
    border-style: solid;
}

.msg {
    display: flex;
    align-items: flex-end;
    margin-bottom: 5px;
}

.msg:last-of-type {
    margin: 0;
}

.msg-bubble {
    max-width: 350px;
    padding: 10px;
    border-radius: 15px;
    font-size: 12px;
    background: #ececec;
}

.left-msg .msg-bubble {
    border-bottom-left-radius: 0;
}

.right-msg {
    flex-direction: row-reverse;
}

.right-msg .msg-bubble {
    background: #FF6700;
    color: #fff;
    border-bottom-right-radius: 0;
}

.right-msg .msg-img {
    margin: 0 0 0 10px;
}

.time-text-muted {
    color: #888ea8 !important;
    font-size: 9px;
}

.msger-inputarea {
    display: flex;
    padding: 5px;
    border-top: var(--border);
}

.msger-inputarea * {
    padding: 10px;
    border: none;
    border-radius: 3px;
    font-size: 1em;
}

.msger-input {
    flex: 1;
    background: #ddd;
}

.msger-send-btn {
    margin-left: 10px;
    background: #FF6700;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.23s;
}

.msger-send-btn:hover {
    background: rgb(0, 180, 50);
}

.chatBody {
    overflow-y: scroll;
    max-height: 60vh;
    white-space: pre-wrap;
}

:where(.css-dev-only-do-not-override-htwhyh).ant-select-single.ant-select-show-arrow .ant-select-selection-item,
:where(.css-dev-only-do-not-override-htwhyh).ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-inline-end: 18px;
    font-size: 12px !important;
}

.btn-secondary {
    background-color: white !important;
    color: #285192 !important;
    border: 1px solid #285192 !important;
}

.startchat .ant-select-selector {
    background-color: #FF6700 !important;
    color: white !important;
}

.startchat .ant-select-selector .ant-select-selection-placeholder {
    color: white !important;
    font-size: 12px !important;
}

.startchat span.ant-select-clear {
    background: transparent !important;
    color: white !important;
    margin-top: -8px !important;
}

.startchat.ant-select-open .ant-select-selection-item {
    color: white !important;
    font-size: 12px !important;
}

.ant-select.startchat .ant-select-arrow .anticon>svg {
    color: white !important;
    font-size: 12px !important;
}