.activity-feed {
    padding: 15px 15px 0 15px;
    list-style: none;
}
.activity-feed .feed-item {
    position: relative;
    padding-bottom: 29px;
    padding-left: 30px;
    border-left: 2px solid #e9ecef;
}

.activity-feed .feed-item::after {
    content: "";
    display: block;
    position: absolute;
    top: -4px;
    left: -9px;
    width: 16px;
    height: 16px;
    border-radius: 30px;
    background: #FF6700;
}
.activity-feed .feed-item .date {
    display: block;
    position: relative;
    top: -5px;
    text-transform: uppercase;
    font-size: 12px;
    color: #515365;
    font-weight: 600;
}
.activity-feed .feed-item .activity-text {
    position: relative;
    top: -3px;
    color: #959ab1;
    font-size: 14px;
}
.font-13 {
    font-size: 13px;
}
.text-success-teal {
    color: #009688 !important;
}