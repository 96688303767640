.ts_Para {
    margin-top: 0;
    color: #515365;
    font-size: 0.875rem;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    font-weight: 400;
}

.item-content {
    background-color: #fff;
    padding: 13px 18px;
    box-shadow: 0px 0px 4px 1px rgba(126, 142, 177, 0.12);
    -webkit-box-shadow: 0px 0px 8px 2px rgba(126, 142, 177, 0.22);
    border-radius: 6px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.user-name {
    margin-top: 0;
    margin-bottom: 0.625rem;
    color: #515365;
    font-size: 11px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.5;
}

.carousel-captions {
    position: absolute;
    top: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}


.carousel-captions1 {
    position: absolute;
    top: 55%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}

.carousel-titles {
    /* background: rgba(0, 0, 0, .6); */
    color: white;
    text-align: center !important;
    padding: 5px;
    border-radius: 10px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.40) !important;
    background-color: #FF6700 !important;
    color: white !important;
    text-align: center;
}

.rounded-corner-pills-icon .nav-pills li a:hover {
    background-color: #d8d8d8;
    transition: all ease 0.5s;
}

.rounded-corner-pills-icon .nav-pills li a {
    border-radius: 0.5rem !important;
    background-color: #f1f2f3;
    width: 100px;
    padding: 8px;
    transition: all ease 0.5s;
    color: #3b3f5c !important;
    text-align: center;
}

.pillsIcon {
    display: block;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    font-size: 30px;
}

.annualTitles {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0px;
    margin-bottom: 0;
    color: #515365;
}

.btn-primarys {
    color: #fff !important;
    background-color: #FF6700 !important;
    border-color: #FF6700;
}

.btn-primarys a{
    color: #fff !important;
}

.numberCircle {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    padding: 3px;
    background: #fff;
    /* border: 2px solid #666; */
    color: #ffffff;
    text-align: center;
    background-color: #FF6700;
    font-size: 12px;
    /* font: 32px Arial, sans-serif; */

}

select.selectForm.form-select {
    width: 97% !important;
    color: #2f44b2 !important;
    font-size: 14px !important;
}

td.ant-table-cell {
    font-family: 'Poppins', sans-serif !important;
}

@media (max-width: 767px) {
    .carousel-captions {
        position: absolute;
        top: 0%;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: center;
    }


    .carousel-captions1 {
        position: absolute;
        top: 50%;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: center;
        padding-left: 7px;
    }
}

.react-multiple-carousel__arrow::before {
    font-size: 12px !important;
    color: #fff;
    display: block;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: relative;
}

.react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    background: rgba(0, 0, 0, 0.5);
    min-width: 30px !important;
    min-height: 30px !important;
    opacity: 1;
    cursor: pointer;
}

.react-multiple-carousel__arrow--right {
    right: calc(0% + 0px) !important;
    margin-top: -80px !important;
}

.react-multiple-carousel__arrow--left {
    left: calc(0% + 0px) !important;
    margin-top: -80px !important;

}

@media only screen and (max-width: 600px) {
    .annualBannerTitles {
        font-size: 12px;
    }

    .conferenceBanner {
        height: 250px;
    }

    .annualBannerLogo {
        width: 50%;
    }

    .annualBannerBtn {
        font-size: 12px;
        margin-top: 15px;
    }
}

.attendeesPic .ant-upload span .ant-btn {
    width: 150px !important;
}

.attendeesPic {
    display: flex !important;
    flex-direction: row !important;
}

.attendeesPic .ant-upload-list-picture .ant-upload-list-item-container .ant-upload-list-item {
    height: 38px !important;
    margin-top: 0px !important;
    margin-left: 20px !important;
}

.fontweight {
    font-weight: 500;
}

.attendeesPic .ant-upload .ant-btn .anticon-upload svg {
    margin-top: -6px !important;
}

/* hr {
    background-color: #fff;
    padding: 0;
    margin: 80px;
} */

.formHr {
    border: 0;
    border-top: 1px dashed #eeeeee;
    border-bottom: 1px dashed #fff;
}
.btn-primaryss{
    background: "1B4D70" !important;
    font-size: 14px;
}